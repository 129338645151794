<template>
    <div class="collectPage">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="collectPage_main">
            <div class="collectPage_main_header">
                <div class="collectPage_main_header_left">
                    <p>收藏夹</p>
                    <p>
                        <el-progress :percentage="num" :show-text="false" :stroke-width="10"></el-progress>
                        <span>{{this.tabIndex}}/{{this.collectList.length}}</span>
                    </p>
                </div>
                <div class="collectPage_main_header_right">
                    <p class="collectPage_main_header_right_view">
                        <img src="@/assets/exam/calculator.png" alt="">
                        <span>计算器</span>
                    </p>
                    <p class="collectPage_main_header_right_wire"></p>
                    <p class="collectPage_main_header_right_view" @click="back">
                        <img src="@/assets/exam/backCollect.png" alt="">
                        <span>返回收藏夹</span>
                    </p>
                    <p class="collectPage_main_header_right_wire"></p>
                    <p class="collectPage_main_header_right_view" @click="subject_delCollect">
                        <img src="@/assets/exam/removeCollect.png" alt="">
                        <span>收藏移除</span>
                    </p>
                </div>
            </div>
            <div class="collectPage_main_testView">
                <div class="collectPage_main_testView_icon"></div>
                <div class="collectPage_main_testView_tips" v-if="collectDetail.classify==1">单项选择题</div>
                <div class="collectPage_main_testView_tips" v-if="collectDetail.classify==2">多项选择题</div>
                <div class="collectPage_main_testView_tips" v-if="collectDetail.classify==3">判断题</div>
                <div class="collectPage_main_testView_tips" v-if="collectDetail.classify==4">简答题</div>
                <div class="collectPage_main_testView_tips" v-if="collectDetail.classify==5">综合题</div>
                <div class="collectPage_main_testView_title">{{collectDetail.subjectTitle}}</div>
                <div class="collectPage_main_testView_options">
                    <p v-for="(item,index) in collectDetail.answerList" :key="index">
                        <span>{{item.indexes}}</span><span v-if="item.type==1">{{item.answerName}}</span>
                        <span v-if="item.type==2"><img :src="item.answerPic" alt=""></span>
                    </p>
                </div>
                <div class="collectPage_main_testView_judge">
                    <img src="@/assets/exam/error.png" alt="">
                    <div class="collectPage_main_testView_judge_left">
                        <span>您的答案：</span>
                        <span v-if="collectDetail.doneAnswer=='' || collectDetail.doneAnswer==null || collectDetail.doneAnswer==undefined" style="color:#F25E64;">未作答</span>
                        <span v-else style="color:#F25E64;">{{collectDetail.doneAnswer}}</span>
                    </div>
                    <div class="collectPage_main_testView_judge_right">
                        <span>参考答案：</span>
                        <p v-for="(item,index) in answerList" :key="index"  >
                            <span v-if="item.type==1" style="color:#4ECB8A;">{{item.name}}</span>
                            <img :src="item.pic" alt="" v-else>
                        </p>
                    </div>
                    <div class="collectPage_main_testView_judge_right">
                        <span>全站正确率：</span>
                        <p>
                            {{questionObj.totalAccuracy}}
                        </p>
                    </div>
                </div>
                <div class="collectPage_main_testView_analysis">
                    <!-- <div class="collectPage_main_testView_analysis_testing">
                        <p>
                            <span>考</span>
                            <span>点：</span>
                        </p>
                        <p>
                            <span v-for="(item,index) in collectDetail.knowledgeList" :key="index">{{item.knowledgeName}}</span>
                        </p>
                    </div> -->
                    <div class="collectPage_main_testView_analysis_content">
                        <p style="font-weight:500;">答案解析：</p>
                        <p v-html="collectDetail.analysisText"></p>
                        <p v-if="collectDetail.analysisVideo!='' && collectDetail.analysisVideo!=null && collectDetail.analysisVideo!=undefined">
                            <video :src="collectDetail.analysisVideo!=''"></video>
                        </p>
                    </div>
                </div>
                <div class="collectPage_main_testView_btn" v-if="collectList.length>1">
                    <el-button @click="prev">上一题</el-button>
                    <el-button type="primary" @click="next">下一题</el-button>
                </div>
            </div>
            
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { subject_analysis,collect_list,subject_delCollect,questionStatistics  } from "@/api/exam";
export default {
    name:'collectPage',
    components: {
        t_header,
        t_footer,
    },
    data(){
        return{
            tabNumNew:0,
            questionObj:{},
            isSort:false,
            subjectId:'',
            secondId:'',
            tabIndex:'',
            collectDetail:{},
            collectList:[],
            num:0,
            answerList:[],
            classify:null,
            isUserInfo:false,
        }
    },
    mounted(){
        this.subjectId=this.$route.query.subjectId
        this.classify=this.$route.query.classify
        this.subject_analysis()
        this.collect_list()
    },
    methods:{
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        // 返回收藏夹
        back(){
            this.$router.go(-1);
        },
        subject_analysis(){
            var obj={
                subjectId:this.subjectId
            }
            subject_analysis(obj).then(res=>{
                if(res.data.code==0){
                    this.collectDetail=res.data.data
                    var arr=[]
                    for(var i in this.collectDetail.answerList){
                        if(this.collectDetail.answerList[i].isRight==1){
                            var obj={
                                name:'',
                                pic:'',
                                type:0
                            }
                            if(this.collectDetail.answerList[i].type==1){
                                obj.name=this.collectDetail.answerList[i].indexes
                                obj.type=1
                                arr.push(obj)
                            }else{
                                obj.pic=this.collectDetail.answerList[i].answerPic
                                obj.type=2
                                arr.push(obj)
                            }
                            this.answerList=arr
                        }
                    }
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
            questionStatistics({ subjectId: this.subjectId }).then(
                res => {
                    this.questionObj = res.data.data;
                    

                }
            );
        },
        // 习题收藏章节列表
        collect_list(){
            var obj={
                classify:this.classify,
                page:0,
                pageSize:10
            }
            collect_list(obj).then(res=>{
                if(res.data.code==0){
                    this.collectList=res.data.data
                    for(var i in this.collectList){
                        if(this.collectList[i].subjectId==this.subjectId){
                            this.tabIndex=Number(i)+1
                            this.num=Number(Number((this.tabIndex/this.collectList.length)*100).toFixed(2))
                        }
                    }
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 上一题
        prev(){
            if(this.tabNumNew>0){
                this.tabNumNew = this.tabNumNew -1;
                this.subjectId=this.collectList[this.tabNumNew].subjectId;
                this.tabIndex=this.tabIndex-1;
                
                this.num=Number(Number((this.tabIndex/this.collectList.length)*100).toFixed(2))
                this.subject_analysis()
            }
        },
        // 下一题
        next(){
            if(this.tabIndex<=this.collectList.length){
                this.tabNumNew = this.tabNumNew + 1;
                this.subjectId=this.collectList[this.tabNumNew].subjectId;
                this.tabIndex=this.tabIndex+1;
                this.num=Number(Number((this.tabIndex/this.collectList.length)*100).toFixed(2))
                this.subject_analysis()
            }
        },
        // 移除收藏
        subject_delCollect(){
            var obj={
                subjectIds:this.subjectId
            }
            subject_delCollect(obj).then(res=>{
                if(res.data.code==0){
                    if(this.tabIndex>1){
                        this.subjectId=this.collectList[this.tabIndex].subjectId
                        this.subject_analysis()
                        this.collectList.splice(this.tabIndex-1,1)
                    }else{
                        this.$router.go(-1)
                    }
                    // if(this.tabIndex<=this.collectList.length){

                    // }
                }
            }).catch(error=>{
                console.log(error)
            })
        },
    }
}
</script>

<style>

</style>